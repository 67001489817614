var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardBody',[(_vm.role!=='Verifier')?_c('CRow',{staticClass:"text-center"},_vm._l((_vm.entitiesNumber),function(item,index){return (_vm.checkPermission(item.permission))?_c('CCol',{staticClass:"mb-sm-2 mb-0",attrs:{"md":"","sm":"12"},on:{"click":function($event){return _vm.gotoPage(item.url)}}},[_c('strong',[_vm._v(_vm._s(item.total)+" "+_vm._s(index))]),_c('CProgress',{staticClass:"progress-xs mt-2",attrs:{"precision":1,"color":item.color,"value":100}})],1):_vm._e()}),1):_vm._e(),(_vm.role==='Verifier')?_c('CRow',{staticClass:"text-center"},_vm._l((_vm.VerifierData),function(item,index){return _c('CCol',{staticClass:"mb-sm-2 mb-0",attrs:{"md":"","sm":"12"},on:{"click":function($event){return _vm.gotoPage(item.url)}}},[_c('strong',[_vm._v(_vm._s(item.total)+" "+_vm._s(index))]),_c('CProgress',{staticClass:"progress-xs mt-2",attrs:{"precision":1,"color":item.color,"value":100}})],1)}),1):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[(_vm.userType === 'Verifier')?_c('div',[_c('staff-calendar')],1):_vm._e(),(_vm.userType === 'Admin')?_c('div',[_c('calendar-view')],1):_vm._e()]),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('OpportunityChart')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('ResourceChart')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Latest Profiles")]),_c('span',{staticStyle:{"float":"right"}},[_c('CBadge',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.count))])],1)]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","no-sorting":""},on:{"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"c-avatar"},[_c('img',{staticClass:"c-avatar-img",attrs:{"src":item.avatar.url,"alt":""}}),_c('span',{staticClass:"c-avatar-status",class:("bg-" + (item.avatar.status || 'secondary'))})])])}},{key:"user",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.user.name))]),_c('div',{staticClass:"small text-muted"},[_c('span',[[_vm._v(" Title: "+_vm._s(item.user.title))],[_vm._v(" | Company: "+_vm._s(item.user.company))]],2)])])}},{key:"industry",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.industry.name))]),_c('div',{staticClass:"small text-muted"},[_c('span',[_vm._v(" "+_vm._s(item.industry.sub)+" ")])])])}},{key:"country",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"small text-muted"},[_c('span',[[_vm._v(" "+_vm._s(item.country.name))]],2),_c('br'),_c('span',[[_vm._v(" "+_vm._s(item.country.state)+" , "+_vm._s(item.country.city)+" ")]],2)])])}},{key:"usage",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left"},[_c('strong',[_vm._v(_vm._s(item.usage.value)+"%")])]),_c('div',{staticClass:"float-right"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.usage.period))])])]),_c('CProgress',{staticClass:"progress-xs",attrs:{"color":_vm.color(item.usage.value)},model:{value:(item.usage.value),callback:function ($$v) {_vm.$set(item.usage, "value", $$v)},expression:"item.usage.value"}}),_vm._v(" RecentTransaction ")],1)}},{key:"activity",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"small text-muted"},[_vm._v("Last login")]),_c('strong',[_vm._v(_vm._s(item.activity))])])}}],null,false,1883764397)}),(_vm.showProfile)?_c('CRow',{staticStyle:{"margin-top":"2px"}},[_c('CCol',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-light btn-block mb-1",attrs:{"title":"View All Profile"},on:{"click":_vm.viewProfiles}},[_vm._v(" View All ")])])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentOpportunities')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentResources')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentInquiry')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentOpportunityReport')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentResourceReport')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentGroupPost')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentGroup')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentOpportunityResponse')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentResourceResponse')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentTransaction')],1):_vm._e(),(_vm.userType === 'Admin')?_c('CCol',{attrs:{"md":"6"}},[_c('RecentPendingProfile')],1):_vm._e()],1),(_vm.userType === 'Admin')?_c('div',[_c('pending-profile-update-request')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }