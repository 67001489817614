<template>
  <CRow>
    <CCol col="12">
      <CModal :title="title" size="lg" :show.sync="schedule" :no-close-on-backdrop="true" color="primary">
        <CForm novalidate @submit.prevent="onSubmit" enctype='multipart/form-data'>
          <CCardBody>
            <CRow>

              <CCol md="6" v-show="calendar.meetingLink">
                <div class="form-group"  >
                  <label class="form__label"><b>{{ $lang.calendar.form.registeredAs }}</b></label><br>
                  {{ getCalendar.registeredAs }}
                </div>
              </CCol>
              <CCol md="6" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.name }}</b></label><br>
                  {{ getCalendar.name }}
                </div>
              </CCol>


              <CCol md="6" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.date }}</b></label><br>
                  {{ getCalendar.displayScheduleDate }}
                </div>
              </CCol>
              <CCol md="6" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.time }}</b></label><br>
                  {{ getCalendar.displayStartTime }} <b>To</b>  {{ getCalendar.displayEndTime }}
                </div>
              </CCol>


              <CCol md="6" :hidden="user_hidden" v-if="getCalendar.isCompleted==0">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.meetingLink }}</b></label><br>
                  <a :href="calendar.meetingLink" target="_blank">Join Meeting</a><br>

                </div>
              </CCol>

              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.staffPassword }}</b></label><br>
                  {{ calendar.staffPassword }}
                </div>
              </CCol>
              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.meetingStatus }}</b></label><br>
                  {{ calendar.meetingStatus }}
                </div>
              </CCol>

              <CCol md="6" v-if="getCalendar.isCompleted==1">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.verifyAt }}</b></label><br>
                  {{ calendar.verifyAt }}
                </div>
              </CCol>
              <CCol md="6" v-show="calendar.description" >
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.description }}</b></label><br>
                  {{ calendar.description }}
                </div>
              </CCol>

              <!--
              <CCol md="6" hidden>
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.endTime }}</b></label><br>
                  {{ calendar.end_time }}
                </div>
              </CCol>


              <CCol md="6" :hidden="user_hidden">
                <div class="form-group">
                  <label class="form__label"><b>{{ $lang.calendar.form.userId }}</b></label><br>
                  {{ calendar.mobileuser }}
                </div>
              </CCol>
              -->

              <CCol v-show="calendar.meetingLink">
                <a :href="calendar.profileLink" style="float: right" class="btn btn-primary right" target="_blank">
                  <CIcon name="cil-check-circle"/>
                  View Profile
                </a>
              </CCol>

            </CRow>
          </CCardBody>

        </CForm>
        <template #footer style="display: none;">
          <CButton @click="darkModal = false" color="danger" style="display: none;">Discard</CButton>
          <CButton @click="darkModal = false" color="success" style="display: none;">Accept</CButton>
        </template>
      </CModal>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.calendar.title }}</strong>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <FullCalendar :options="calendarOptions"/>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import moment from 'moment';
import {Mixin} from "/src/mixins/index.js";
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import {required} from "vuelidate/lib/validators";
import {calendar} from "/src/store/url.js";
import {Settings} from "../../store/settings";
import 'vue2-timepicker/dist/VueTimepicker.css'
import options from "/src/validations/validations.js";

Vue.use(VueForm, options);

export default {
  name: 'CalendarView',
  mixins: [Mixin],
  components: {
    FullCalendar
  },
  data() {
    return {
      self: this,
      schedule: false,
      module: calendar,
      hidden: true,
      div_hidden: false,
      footer_hidden: false,
      user_hidden: true,
      title: '',
      edit_id: '',
      getCalendar:[],
      calendar: {
        date: '',
        start_time: '',
        end_time: '',
        user: '',
        meetingLink: '',
        description: '',
        mobileuser: '',
        verifyAt: '',
        meetingStatus: '',
        profileLink: '',
        staffPassword: '',
      },
      userOptions: [],
      Settings: Settings,
      calendarOptions: {
        selectable: true,
        editable: false,
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridDay',
        allDaySlot: false,
        slotDuration: '00:10:00',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        selectAllow: function (select) {
          return moment().diff(select.start) <= 0
        },
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth'
        },
        events: [],
        eventRender: this.handleEventRender,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false
        },
        dayMaxEvents: true,
      },
    }
  },
  validations: {
    calendar: {
      user: {
        required
      },
    }
  },
  mutations: {

  },
  mounted() {
    let self = this;
    const userid = localStorage.getItem("user_id");
    axios.post('/calendar/get-calender/' + userid)
        .then((response) => {
          self.calendarOptions.events = response.data.data;
        });
    store.commit('showLoader', false);
  },
  methods: {
    handleEventClick(clickInfo) {
      this.edit_id = clickInfo.event.id;
      this.hidden = false;
      this.div_hidden = true,
          this.footer_hidden = false,
          this.user_hidden = true,
          this.schedule = true;
      this.title = 'View Schedule';
      let self = this;
      axios.post('/calendar/get-timetable-detail/' + this.edit_id)
          .then(function (response) {
            if (response.status === 200) {
              let calendar = response.data.data;
              console.log(calendar);
              self.getCalendar = calendar;
              self.calendar.date = calendar.startDate;
              self.calendar.start_time = calendar.startTime;
              self.calendar.end_time = calendar.endTime;
              self.userOptions = [];
              if (calendar.userIds > 0) {
                self.title = 'View Schedule';
                self.footer_hidden = true;
                self.user_hidden = false;
                self.calendar.meetingLink = calendar.meetingLink;
                self.calendar.profileLink = '#/calendar-user/view/' + calendar.userIds;
                self.calendar.description = calendar.description;
                self.calendar.mobileuser = calendar.user;
                self.calendar.verifyAt = calendar.verifyAt;
                self.calendar.meetingStatus = calendar.meetingStatus;
                self.calendar.staffPassword = calendar.staffPassword;
                calendar.users.map(function (value, key) {
                  self.userOptions.push({id: value.id, label: value.staffName});
                });
                self.calendar.user = {
                  id: calendar.userId, label: calendar.userName
                };
              } else {
                self.calendar.user = calendar.userName;
              }
            }
          });
    },
    handleEventRender(info) {
      const tooltip = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        placement: 'top',
        trigger: 'hover',
        container: 'body'
      });
    },
  }
}
</script>

<style>
.vue__time-picker input.display-time {
  float: left;
  width: 100%;
}

#time_picker[readonly] {
  background-color: transparent;
  opacity: 1;
}
</style>
