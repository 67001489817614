<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow class="text-center" v-if="role!=='Verifier'">
          <CCol md sm="12" v-if="checkPermission(item.permission)" v-on:click="gotoPage(item.url)"
                v-for="(item, index) in entitiesNumber" class="mb-sm-2 mb-0">
            <strong>{{ item.total }} {{ index }}</strong>
            <CProgress class="progress-xs mt-2" :precision="1" :color="item.color" :value="100"/>
          </CCol>
        </CRow>
        <CRow class="text-center" v-if="role==='Verifier'">
          <CCol md sm="12" v-on:click="gotoPage(item.url)"
                v-for="(item, index) in VerifierData" class="mb-sm-2 mb-0">
            <strong>{{ item.total }} {{ index }}</strong>
            <CProgress class="progress-xs mt-2" :precision="1" :color="item.color" :value="100"/>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol md="12">
        <div v-if="userType === 'Verifier'">
          <staff-calendar></staff-calendar>
        </div>
        <div v-if="userType === 'Admin'">
          <calendar-view></calendar-view>
        </div>
      </CCol>
      <!--      <CCol md="6">-->
      <!--        <PostChart></PostChart>-->
      <!--      </CCol>-->
      <CCol md="6" v-if="userType === 'Admin'" >
        <OpportunityChart></OpportunityChart>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'" >
        <ResourceChart></ResourceChart>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <CCard>
          <CCardHeader>
            <strong>Latest Profiles</strong>
            <span style="float: right;">
              <CBadge color="success">{{ count }}</CBadge>
            </span>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="tableItems"
                :fields="tableFields"
                @row-clicked="rowClickHandler"
                head-color="light"
                no-sorting
            >
              <td slot="avatar" class="text-center" slot-scope="{ item }">
                <div class="c-avatar">
                  <img :src="item.avatar.url" class="c-avatar-img" alt=""/>
                  <span
                      class="c-avatar-status"
                      :class="`bg-${item.avatar.status || 'secondary'}`"
                  ></span>
                </div>
              </td>
              <td slot="user" slot-scope="{ item }">
                <div>{{ item.user.name }}</div>
                <div class="small text-muted">
                  <span>
                    <template> Title: {{ item.user.title }}</template>
                    <template> | Company: {{ item.user.company }}</template>
                  </span>
                </div>
              </td>
              <td slot="industry" slot-scope="{ item }">
                <div>{{ item.industry.name }}</div>
                <div class="small text-muted">
                  <span>
                    {{ item.industry.sub }}
                  </span>
                </div>
              </td>
              <td slot="country" slot-scope="{ item }" class="text-center">
                <div class="small text-muted">
                  <span>
                    <template> {{ item.country.name }}</template>
                  </span>
                  <br/>
                  <span>
                    <template>
                      {{ item.country.state }} , {{ item.country.city }}
                    </template>
                  </span>
                </div>
              </td>
              <td slot="usage" slot-scope="{ item }">
                <div class="clearfix">
                  <div class="float-left">
                    <strong>{{ item.usage.value }}%</strong>
                  </div>
                  <div class="float-right">
                    <small class="text-muted">{{ item.usage.period }}</small>
                  </div>
                </div>
                <CProgress
                    class="progress-xs"
                    v-model="item.usage.value"
                    :color="color(item.usage.value)"
                />
                RecentTransaction
              </td>
              <td slot="activity" slot-scope="{ item }">
                <div class="small text-muted">Last login</div>
                <strong>{{ item.activity }}</strong>
              </td>
            </CDataTable>
            <CRow v-if="showProfile" style="margin-top: 2px">
              <CCol class="col-12 text-right">
                <button title="View All Profile" v-on:click="viewProfiles" class="btn btn-light btn-block mb-1">
                  View All
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <!--      <CCol md="6">-->
      <!--        <RecentPosts></RecentPosts>-->
      <!--      </CCol>-->
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentOpportunities></RecentOpportunities>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentResources></RecentResources>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentInquiry></RecentInquiry>
      </CCol>
      <!--      <CCol md="6">-->
      <!--        <RecentPostReport></RecentPostReport>-->
      <!--      </CCol>-->
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentOpportunityReport></RecentOpportunityReport>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentResourceReport></RecentResourceReport>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentGroupPost></RecentGroupPost>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentGroup></RecentGroup>
      </CCol>
      <!--      <CCol md="6">-->
      <!--        <RecentPostResponse></RecentPostResponse>-->
      <!--      </CCol>-->
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentOpportunityResponse></RecentOpportunityResponse>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentResourceResponse></RecentResourceResponse>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentTransaction></RecentTransaction>
      </CCol>
      <CCol md="6" v-if="userType === 'Admin'">
        <RecentPendingProfile></RecentPendingProfile>
      </CCol>
    </CRow>
    <div v-if="userType === 'Admin'">
      <pending-profile-update-request></pending-profile-update-request>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import store from "../store/store";
import {
  dashboardProfileList,
  dashboardNumberOfEntitiesList,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import PendingProfileUpdateRequest from "./profile/PendingProfileUpdateRequest";
import RecentOpportunities from "./dashboardComponents/RecentOpportunities";
import RecentResources from "./dashboardComponents/RecentResources";
import RecentInquiry from "./dashboardComponents/RecentInquiry";
import RecentGroupPost from "./dashboardComponents/RecentGroupPost";
import RecentOpportunityReport from "./dashboardComponents/RecentOpportunityReport";
import RecentResourceReport from "./dashboardComponents/RecentResourceReport";
import OpportunityChart from "./dashboardComponents/OpportunityChart";
import ResourceChart from "./dashboardComponents/ResourceChart";
import StaffCalendar from "./profile/StaffCalendar";
import CalendarView from "./calendar/CalendarView";
import RecentGroup from "./dashboardComponents/RecentGroup";
import RecentOpportunityResponse from "./dashboardComponents/RecentOpportunityResponse";
import RecentResourceResponse from "./dashboardComponents/RecentResourceResponse";
import RecentTransaction from "./dashboardComponents/RecentTransaction";
import RecentPendingProfile from "./dashboardComponents/RecentPendingProfile";

Vue.use(ServerTable, {}, false);
Vue.use(VueTelInput);
export default {
  name: "DashboardItem",
  mixins: [Mixin],
  components: {
    RecentPendingProfile,
    RecentTransaction,
    RecentResourceResponse,
    RecentOpportunityResponse,
    // RecentPostResponse,
    RecentGroup,
    PendingProfileUpdateRequest,
    // RecentPosts,
    RecentOpportunities,
    RecentResources,
    RecentInquiry,
    // RecentPostReport,
    RecentOpportunityReport,
    RecentGroupPost,
    RecentResourceReport,
    StaffCalendar,
    CalendarView,
    ResourceChart,
    // PostChart,
    OpportunityChart,
  },
  data() {
    return {
      alertMessage: "",
      userType: localStorage.getItem("roleName"),
      role : localStorage.getItem("roleName"),
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      dismissCountDownS: 0,
      self: this,
      count: 0,
      moduleDashboardProfileList: dashboardProfileList,
      moduleDashboardNumberOfEntitiesList: dashboardNumberOfEntitiesList,
      locationText: "",
      showProfile: false,
      phone: "",
      dismissCountDown: 0,
      submitStatus: null,
      selected: "Month",
      entitiesNumber: [],
      VerifierData:[],
      tableItems: [],
      listUrl: "/dashboard/profile/requests/list/",
      tableFields: [
        {key: "avatar", label: "", _classes: "text-center"},
        {key: "user"},
        {key: "industry"},
        {key: "country", _classes: "text-center"},
        {key: "registered"},
      ],
      columns: ["name", "status", "addedRequest", "actions"], //'title','company'
      data: [],
      options: {
        headings: {
          name: this.$lang.profileRequest.table.name,
          status: this.$lang.profileRequest.table.status,
          addedRequest: this.$lang.profileRequest.table.addedRequest,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    // eslint-disable-next-line no-undef
    axios
        .get(this.listUrlApi(this.moduleDashboardProfileList))
        .then((response) => {
          self.tableItems = response.data.data;
          self.count = response.data.count;
          if (response.data.length > 0) {
            self.showProfile = true;
          }
        });
    // eslint-disable-next-line no-undef
    axios
        .get(this.listUrlApi(this.moduleDashboardNumberOfEntitiesList))
        .then((response) => {
          self.entitiesNumber = response.data.data;
          self.VerifierData = response.data.data.verifierData;
        });
  },
  methods: {
    rowClickHandler(item) {
      this.$router.push({path: `profile/view/${item.user.id}`});
    },
    viewProfiles() {
      this.$router.push({path: "profile"});
    },
    gotoPage(page) {
      if(page!="") {
        this.$router.push({path: page});
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
